<template>
  <in-page-nav-bottom v-if="$vuetify.breakpoint.smAndDown" :navigation="navArray" />
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'

import InPageNavBottom from '~/components/in-page-nav/InPageNavBottom.vue'

export default Vue.extend({
  name: 'BusinessBillingNav',
  components: {
    InPageNavBottom,
  },
  computed: {
    ...mapGetters('business', ['companyHasManualInvoicingEnabled']),
    navArray() {
      const nav = [
        ...(!this.companyHasManualInvoicingEnabled
          ? [
              {
                routeName: 'business-billing',
                displayTitle: this.$t('business.billing.navigation.billing'),
                icon: '$vuetify.icons.wallet',
              },
            ]
          : []),
        {
          routeName: 'business-billing-payment',
          displayTitle: this.companyHasManualInvoicingEnabled
            ? this.$t('global.payment_details')
            : this.$t('business.billing.navigation.payment_method'),
          icon: '$vuetify.icons.payment_method',
        },
      ]
      return nav
    },
  },
})
</script>

<style lang="sass"></style>
